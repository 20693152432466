import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  terminalHero,
  terminalTrusted,
  terminalWhiteGlove,
  terminalLargeFeatures,
  terminalBusinessTypesCar,
  terminalVideo,
  terminalTestimonials,
  terminalCTA,
  // terminalFaqs,
} from "../../../data/product-pages/terminal-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import {
  heroSuccess,
  minuteManPressSlides,
} from "../../../data/success-stories-data";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const whiteGloveImport = "new-terminal-white-glove.png";

const terminal = () => {
  return (
    <Layout>
      <SEO
        title="Virtual Terminal for Payment Processing | Mobile POS System | SpotOn"
        description="SpotOn's virtual terminal payment processing allows you to send invoices, take mobile and online payments, set up recurring billing plans, and more."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={terminalHero} circleBg={false} />
      <TrustedNumbers numbersArray={terminalTrusted} />
      <WhiteGlove
        sectionData={terminalWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={terminalLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={terminalBusinessTypesCar} />
      <SuccessStories sectionData={heroSuccess} slides={minuteManPressSlides} />
      <VideoSection sectionData={terminalVideo} />
      <TestmonialReviews sectionData={terminalTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={terminalCTA} />
      {/* <Faqs sectionData={terminalFaqs} /> */}
    </Layout>
  );
};

export default terminal;
