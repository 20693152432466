// eslint-disable-next-line import/prefer-default-export
export const terminalHero = {
  title: "Mobile and virtual terminals for payment processing",
  subtext:
    "Send invoices, take mobile and online payments, and set up recurring billing plans, whether you’re in the office or on-the-go. SpotOn’s easy-to-use platform also helps you reach more customers and drive repeat business with built-in marketing and customer loyalty tools.",
  heroImg: "terminal-pp-hero.png",
  imageClassName: "smaller-hero",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/virtual-terminal/demo",
  },
  fatTitle: "Terminal",
};

export const terminalTrusted = [
  {
    title: "300m+",
    subtext: "Marketing campaigns",
  },
  {
    title: "5m+",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+",
    subtext: "Million consumers",
  },
];

export const terminalWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "We’re dedicated to your success",
  content:
    "Your time is valuable, and you don’t want to spend it trying to figure out complicated payment technology. That’s why we’ve designed our payment options to be streamlined and easy to use. Our team will help configure your mobile payments and virtual terminal with your local sales tax and any surcharges you might want to include, and then show you how everything works. And we’ll continue to be there for you whenever you need help with 24/7 lifetime support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/virtual-terminal/demo",
  },
};

export const terminalLargeFeatures = {
  sectionTitle: "Powerful tools to do business your way",
  featureBlocks: [
    {
      blockTitle: "Run payments, send invoices & more with Virtual Terminal",
      blockSubTitle:
        "No hardware, no problem. Virtual Terminal gives you more ways to get paid, and gives your clients convenience and flexibility to pay their way.",
      blockImg: "vt-a.png",
      blockList: [
        "Run one-time & recurring transactions electronically",
        "Save time with built-in tools to work more efficiently, including card-on- file ",
        "Invoice customers & accept upfront deposits",
        "Accept upfront deposits",
        "Send digital receipts and automatic payment reminders ",
        "Grow your customer list and boost revenue with built-in marketing tools",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/virtual-terminal/demo",
      },
    },
    {
      blockTitle: "Accept secure payments with our free app",
      blockSubTitle:
        "The SpotOn payments mobile app makes it easy to accept payments securely right from your phone or tablet. Simply enter the customer’s card information directly onto your phone - no hardware needed.",
      blockImg: "vt-c.png",
      blockList: [
        "Process card payments, voids, and refunds",
        "Save money by adding a surcharge or cash discount",
        "Available for iPhone and Android",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/virtual-terminal/demo",
      },
    },
    {
      blockTitle: "Move faster with a Bluetooth card reader",
      blockSubTitle:
        "Add an optional SpotOn Mobile card reader to create a frictionless payment process. Your customers can swipe, dip, or tap a card, or go mobile with Apple Pay and Android Pay. ",
      blockList: [
        "EMV and NFC enabled to accept swipes, dips, and taps",
        "Fast & easy pairing with your mobile device",
        "Quick transaction times",
      ],
      blockImg: "vt-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/virtual-terminal/demo",
      },
    },
    {
      blockTitle: "Market, manage, invoice & report",
      blockSubTitle:
        "More than just payments. SpotOn’s easy-to-use payment technology includes powerful tools to help you manage and grow your business. Send marketing emails and deals, manage your online reviews, send invoices with our Virtual Terminal, view sales reports, and even launch a digital loyalty program to drive repeat visits. Best of all, you can do it from one simple online dashboard.",
      blockImg: "vt-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/virtual-terminal/demo",
      },
    },
  ],
};

export const terminalBusinessTypesCar = {
  title: "Get the flexibility you need—no matter what type of business you run",
  subtext:
    "Whether you do business on site, on the road, or simply need a backup payment method, SpotOn hais the right technology for you.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const terminalVideo = {
  title: "See how it works",
  videoBtn: "Watch video",
  posterWrapperClass: "relative",
  posterImg: "spoton-v-m-terminal.png",
  videoUrlId: "lIWWySVNAM0",
};

export const terminalSolutionsSection = {
  title: "More great tools to run your business",
  subText: `Save time and grow revenue with integrated SpotOn technology that makes sense for your business.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const terminalTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const terminalIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept terminals and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const terminalCTA = {
  title: "Sign up for a free demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/virtual-terminal/demo",
  },
};

export const terminalFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const vtSolutionsSlides = [
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Review Management",
    link: "/products/review-management",
    img: "Review management.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
];
